"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _client = require("@apollo/client");

var _useRawContent = require("../gqls/useRawContent");

// import
// graphql import
// definition
var _default = function _default(value) {
  var _useQuery = (0, _client.useQuery)(_useRawContent.getDraftText, {
    variables: {
      input: value
    }
  }),
      data = _useQuery.data;

  var draftText = (data === null || data === void 0 ? void 0 : data.getDraftText) || null;
  return (0, _react.useMemo)(function () {
    return draftText;
  }, [draftText]);
};

exports["default"] = _default;